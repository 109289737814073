
import { defineComponent } from 'vue';
import FileUploadStatus from '@/components/cdn/FileUploadStatus.vue';
import Panel from '@/components/layout/Panel.vue';
import Button from '@/components/controls/Button';

interface Data {
    files: File[];
}

export default defineComponent({
    name: 'UploadFiles',
    components: {
        Button,
        FileUploadStatus,
        Panel,
    },
    emits: ['close'],
    props: {
        path: {
            type: String,
            default: '',
        },
    },
    data: (): Data => ({
        files: [],
    }),
    computed: {
    },
    methods: {
        removeFile(rfile: File): void {
            this.files = this.files.filter((file) => file.name !== rfile.name);
        },
        handleFilesSelected(event: InputEvent) {
            const target = event.target as HTMLInputElement;
            if (!target.files) {
                return;
            }
            (target.files as unknown as File[]).forEach(async (newFile) => {
                if (!this.files.some((file) => file.name === newFile.name)) {
                    this.files.push(newFile);
                }
            });
        },
    },
});
